import * as PIXI from 'pixi.js'
import isMobile from "ismobilejs";

export default class MainButton extends PIXI.Sprite {
    callback_function = null
    bg = null
    fg = null
    enabled = true
    init(_bg, _fg, _callback_function) {
        this.callback_function = _callback_function
        this.bg = PIXI.Sprite.from(PIXI.Texture.from(_bg));
        this.addChild(this.bg);

        this.fg = PIXI.Sprite.from(PIXI.Texture.from(_fg));
        this.fg.alpha = 0
        this.addChild(this.fg);

        this.buttonMode = true;
        this.interactive = true;


        if (isMobile) {
            this.on('touchstart', this.onButtonDown.bind(this))
            this.on('touchend', this.onButtonUp.bind(this))
            this.on('touchendoutside', this.onButtonUp.bind(this))
            this.on('pointertap', this.onClick.bind(this))
        } else {
            this.on('mousedown', this.onButtonDown.bind(this))
            this.on('mouseup', this.onButtonUp.bind(this))
            this.on('mouseupoutside', this.onButtonUp.bind(this))
            this.on('click', this.onClick.bind(this))
        }
    }

    enable() {
        this.enabled = true
        this.alpha = 1
    }
    
    disable() {
        this.enabled = false
        this.alpha = 0.5
    }

    onClick() {
        if (!this.enabled) {
            return
        }
        if (this.callback_function) {
            this.callback_function(this)
        }
    }

    onButtonDown() {
        if (this.fg) {
            this.fg.alpha = 1
        }
    }

    onButtonUp() {
        if (this.fg) {
            this.fg.alpha = 0
        }

    }

}


