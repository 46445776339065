import * as PIXI from 'pixi.js'
import TWEEN from "@tweenjs/tween.js";
import SoundManager from "@/utils/SoundManager";

export default class Rheel extends PIXI.Sprite {

    holder = null
    itemsList = ["item_7", "item_a", "item_b", "item_l", "item_o", "item_q", "item_w", "item_z"]
    itemHeight = 198
    itemWidth = 156
    lastItem = null
    stopItem = ""
    rotateSpeed = 300
    stopHandler = null


    init() {
        this.holder = new PIXI.Sprite()
        this.addChild(this.holder);
        var mask = this.createMask(156, 198)
        this.addChild(mask);
        this.holder.mask = mask
        var item_7 = PIXI.Sprite.from(PIXI.Texture.from("item_7"));
        this.holder.addChild(item_7);
        //156x198

    }

    start(_delay) {
        this.stopItem = ""
        
        var randomItem = this.itemsList[Math.floor(Math.random() * this.itemsList.length)]
        var item = PIXI.Sprite.from(PIXI.Texture.from(randomItem));
        item.y = -this.itemHeight
        this.holder.addChild(item);


        randomItem = this.itemsList[Math.floor(Math.random() * this.itemsList.length)]
        var item2 = PIXI.Sprite.from(PIXI.Texture.from(randomItem));
        item2.y = -this.itemHeight * 2
        this.lastItem = item2
        this.holder.addChild(item2);

        new TWEEN.Tween(this.holder).
            to({ y: -20 }, this.rotateSpeed).
            delay(_delay).
            
            easing(TWEEN.Easing.Cubic.Out).
            start()




        new TWEEN.Tween(this.holder).
            to({ y: this.itemHeight * 2 }, this.rotateSpeed).
            easing(TWEEN.Easing.Linear.None).
            delay(_delay + this.rotateSpeed + 50).
            onStart(function(){
                //new SoundManager().play({name:'start',loop: false})
            }).
            onComplete(function () {
                while (this.holder.children[0]) {
                    this.holder.removeChild(this.holder.children[0]);
                }
                this.holder.addChild(this.lastItem);
                this.holder.y = 0
                this.lastItem.y = 0
                this.process()
            }.bind(this)).
            start()

    }


    process() {
        var randomItem = this.itemsList[Math.floor(Math.random() * this.itemsList.length)]
        var item = PIXI.Sprite.from(PIXI.Texture.from(randomItem));
        item.y = -this.itemHeight
        this.holder.addChild(item);


        randomItem = this.itemsList[Math.floor(Math.random() * this.itemsList.length)]
        if (this.stopItem.length > 0) {
            randomItem = this.stopItem
        }

        var item2 = PIXI.Sprite.from(PIXI.Texture.from(randomItem));
        item2.y = -this.itemHeight * 2
        this.lastItem = item2
        this.holder.addChild(item2);

        if (this.stopItem.length > 0) {
            new TWEEN.Tween(this.holder).
                to({ y: this.itemHeight * 2 + 20 }, this.rotateSpeed).
                easing(TWEEN.Easing.Linear.None).
                onComplete(function () {
                    while (this.holder.children[0]) {
                        this.holder.removeChild(this.holder.children[0]);
                    }
                    this.holder.addChild(this.lastItem);

                    this.holder.y = 20
                    this.lastItem.y = 0
                    new TWEEN.Tween(this.holder).
                        to({ y: 0 }, this.rotateSpeed).
                        delay(0).
                        easing(TWEEN.Easing.Cubic.Out).
                        start()
                    new SoundManager().play({name:'stop',loop: false})
                    if(this.stopHandler){
                        this.stopHandler()
                    }
                }.bind(this)).
                start()
        } else {
            new TWEEN.Tween(this.holder).
                to({ y: this.itemHeight * 2 }, this.rotateSpeed).
                easing(TWEEN.Easing.Linear.None).
                onComplete(function () {
                    while (this.holder.children[0]) {
                        this.holder.removeChild(this.holder.children[0]);
                    }
                    this.holder.addChild(this.lastItem);
                    this.holder.y = 0
                    this.lastItem.y = 0
                    setTimeout(function () {
                        this.process()
                    }.bind(this), 0)
                }.bind(this)).
                start()
        }


    }

    stop(item, handler) {
        this.stopItem = item
        this.stopHandler = handler
    }

    createMask(width, height) {
        var holderMask = new PIXI.Graphics();
        holderMask.beginFill(0x8bc5ff, 1);
        holderMask.moveTo(0, 0);
        holderMask.lineTo(width, 0);
        holderMask.lineTo(width, height);
        holderMask.lineTo(0, height);
        holderMask.lineTo(0, 0);
        return holderMask
    }

}


