<template>
  <div class="container pt-3">
    <div class="row">
      <div class="m-0 p-0">
        <div class="clearfix"></div>
        <div class="gameplay">
          <CanvasRenderer :canvasHandler="canvasHandler" />
        </div>

        <div class="buttons mb-3">
          <div class="lucky777connect" v-if="state.buttonstate == 'unlock'">
            <button class="betbutton" @click="lucky777Game.unlock()">UNLOCK</button>
          </div>
          <div class="clearfix"></div>
          <div class="lucky777connect" v-if="state.buttonstate == 'connect'">
            <button class="betbutton" @click="lucky777Game.connect()">
              CONNECT
            </button>
          </div>
          <div class="clearfix"></div>

          <div class="lucky777connect" v-if="state.buttonstate == 'play'">
            <div class="text-start text-hint ps-1">
              Min Bet {{ minBet }} / Max Bet {{ maxBet }} {{ currency }}
            </div>
            <input class="betinput" type="number" v-model="betAmount" />
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 1)" class="betModifiers">
              x2
            </button>
            <button @click="betAmount = gameHelper.modifyBet(betAmount, 2)" class="betModifiers">
              x/2
            </button>
            <div></div>

            <div class="clearleft"></div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="betstabHolder pt-1">
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'MY'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('MY')">
            MY
          </div>
          <div class="betstab" v-bind:class="
            historyManager.currentBetHistoryList == 'ALL'
              ? 'betstabactive'
              : ''
          " @click="historyManager.setBetHistoryList('ALL')">
            ALL
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="betstabbottomline"></div>

        <TokenHistoryTable :data="historyManager.betHistory" />
      </div>
    </div>
    <Popup v-show="wrongNetwork.state" @close="closePopup" :title="wrongNetwork.title" :body="wrongNetwork.body"
      fontSize="normal" />
    <Popup v-show="isPopupVisible" @close="closePopup" title="INSTANT CASHBACK RULES" :body="getCashbackText()" />
    <PromoPopup
      :state="dailyCashbackPopup.state"
      :title="dailyCashbackPopup.title"
      :body="dailyCashbackPopup.body"
      :closeButton="true"
      fontSize="normal"
    />

  </div>
</template>

<script>
import Lucky777Game from "../index.js";

import gameHelper from "../../helpers/gameHelper.js";
import TokenHistoryTable from "../../components/TokenHistoryTable";
import CanvasRenderer from "./CanvasRenderer";
import Popup from "@/components/Popup.vue";
import CashbackHelper from "../../../services/cashbackhelper.js";
import Cashback from "../../../services/Cashback.min.json";

import HistoryManager from "../../../services/historymanager";
import Lucky777 from "../contracts/Lucky777.min.json";

import Promo2 from "../../../services/Promo2.min.json";
import Promo2Helper from "../../../services/promo2helper";
import PromoPopup from "@/components/PromoPopup.vue";

import Token from "../../../services/Token.min.json";
import TokenHelper from "../../../services/tokenhelper.js";


export default {
  name: "GamePlay",
  components: {
    TokenHistoryTable,
    CanvasRenderer,
    Popup,
    PromoPopup
  },
  data() {
    const state = {
      lucky777message: true,
      lucky777win: false,
      lucky777WinAmount: "0.0",
      gameBet: false,
      gameBetloader: false,
      buttonstate: "connect",
      lucky777MessageStr: "Connecting...",
      game: "lucky777",
      seletectedItems: 0,
    };

    var lucky777Game = null;
    var lucky777View = null;
    var cashbackHelper = null;
    var historyManager = null;
    var promo2Helper = null
    var tokenHelper = null
    return {
      lucky777Game,
      lucky777View,
      cashbackHelper,
      historyManager,
      tokenHelper,
      promo2Helper,
      state,
      currencyCode: this.$store.getters.getConfig.currency_code,
      autobetSettings: false,
      gameHelper: new gameHelper(state.game),
      betAmount: this.$store.getters.getGameLimits[state.game].min,
      minBet: this.$store.getters.getGameLimits[state.game].min,
      maxBet: this.$store.getters.getGameLimits[state.game].max,
      currency: this.$store.getters.getCurrency,
      isPopupVisible: false,
      wrongNetwork: {
        state: false,
        title: 'Wrong Network',
        body: `You are trying to play with {{url network}} but you are connected to {{user network}}. 
                Please connect to {{url networ}} and click reload button below
                <div class="text-center"><a class="btn btn-secondary btn-sm mt-3">Reload</a></div>`,
      },
      dailyCashbackPopup: {
        state: false,
        title: "We've got you covered!",
        body: `<center><div style="text-align: left;font-size:19px;font-weight:600;">As it was your first bet today in this game, we have REFUNDED 1 TT back to your Cashback balance, to retrieve it just press the cashback "Claim" button!</div></center>`,
      }
    };
  },
  unmounted() {
    this.lucky777Game.destroy();
    this.cashbackHelper.destroy();
    this.historyManager.destroy()
    this.promo2Helper.destroy()
    this.tokenHelper.destroy()
  },
  beforeMount() {
    this.historyManager = new HistoryManager(this.historyHandler, this.state.game, Lucky777);
    this.promo2Helper = new Promo2Helper(this.messageHandler, Promo2);

    this.lucky777Game = new Lucky777Game(
      this.messageHandler,
      Lucky777,
      "lucky777"
    );
    this.lucky777Game.historyManager = this.historyManager;
    this.lucky777Game.init();
    this.cashbackHelper = new CashbackHelper(this.messageHandler, Cashback);
    this.tokenHelper = new TokenHelper(this.messageHandler, Token);
    this.$store.getters.getConfig.games['lucky777'].contract = this.lucky777Game.address

  },
  mounted() {
    this.$store.commit("setEnableFast", true);
    this.$nextTick(function () {
      window.addEventListener("resize", this.matchSizes);
    });
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
    "state.lucky777MessageStr": function (gameMessage) {
      if (gameMessage != "") {
        this.$toast.show(gameMessage);
      }
      this.state.lucky777MessageStr = "";
    },
    "$store.state.user.currency": function () {
      this.setCurrency(this.$store.state.user.currency);
    },
  },
  methods: {
    setCurrency(_currency) {
      this.currency = _currency.name.toUpperCase();
      this.haveCashback = _currency.cashback;

      this.lucky777Game.setCurrency(_currency);

      this.state.buttonstate = "play";
      this.minBet = this.$store.getters.getGameLimits[this.state.game].min;
      this.maxBet = this.$store.getters.getGameLimits[this.state.game].max;
    },
    historyHandler(obj, list) {
      if (this.lucky777Game.playeraddress != obj.address) {
        this.historyManager.addBetHistory(obj, list)
      }

    },
    makeBet() {
      if (!this.gameHelper.verify("betAmount", this.betAmount)) {
        return this.$toast.error(
          this.gameHelper.getMessage("invalid_bet_amount")
        );
      }

      this.lucky777Game.betHandler(this.betAmount);
    },
    autobetStart() {
      return this.makeBet();
    },
    autobetProcess() { },
    matchSizes() { },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.wrongNetwork.state = false
    },
    canvasHandler(obj) {
      if (typeof obj === "string" || obj instanceof String) {
        switch (obj) {
          case "claim":
            this.lucky777View.claimButton(true);
            this.cashbackHelper.claim();
            break;

          case "bet":
            this.makeBet();
            this.lucky777View.rheelAction([]);
            break;
          case "info":
            this.showPopup();
            break;
          case "betmax":
            this.betAmount = this.maxBet;
            this.makeBet();
            this.lucky777View.rheelAction([]);
            break;

          case "payout":
            var m = document.getElementById("menu");
            m.click();
            var p = document.getElementById("payout");
            p.click();
            break;
          default:
            break;
        }
      } else {
        this.lucky777View = obj;
      }
    },
    getCashbackText() {
      return `<table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><= 1 000</td>
                    <td>0.5%</td>
                  </tr>
                </tbody>
              <table>

              Example table:<br>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Bet amount</th>
                    <th scope="col">Cashback</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1 000</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>500</td>
                    <td>2.5</td>
                  </tr>
                  <tr>
                    <td>200</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td>0.5</td>
                  </tr>                  
                  <tr>
                    <td>10</td>
                    <td>0.05</td>
                  </tr>
                </tbody>
              </table>
              * Cashback is rewarded only on lost bets<br>
              * Cashback is rewarded for any bet size<br>
              * Cashback has no wagering terms, can be claimed instantly<br>`;
    },
    messageHandler(obj) {
      if (obj.type == "limits") {
        window.Logger.log(obj)
        this.betAmount = obj.min;
        this.minBet = obj.min;
        this.maxBet = obj.max;
        this.$store.getters.getGameLimits[this.state.game].min = this.minBet;
        this.$store.getters.getGameLimits[this.state.game].max = this.maxBet;
        return;
      }
      //
      if (obj.type == "tokennotalowed") {
        this.state.buttonstate = "unlock";
        return;
      }
      if (obj.type == "tokenalowed") {
        this.state.buttonstate = "play";
        return;
      }



      if (obj.type == "accountset") {
        if (this.$store.getters.getUserWallet != obj.msg) {
          this.$store.commit("setUserWallet", obj.msg);
        }

        this.lucky777Game.playeraddress = obj.msg;
        if (this.cashbackHelper.account == null) {
          this.cashbackHelper.onConnet(obj.msg);
          this.cashbackHelper.balanceOf();
        }
        if (this.promo2Helper.account == null) {
          console.log(obj.msg)
          this.promo2Helper.onConnet(obj.msg);
        }
        if (this.tokenHelper.account == null) {
          this.tokenHelper.onConnet(obj.msg);
          this.tokenHelper.balanceOf();
        }
        if (
          this.$store.state.user.currency.address !=
          this.lucky777Game.tokenObj.address
        ) {
          this.setCurrency(this.$store.state.user.currency);
        }

        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      if (obj.type == "dailycashback") {
        console.log(obj)
        this.dailyCashbackPopup.state = true;
      }

      if (obj.type == "cashbackadd") {
        this.lucky777View.setCashback(obj.balance);
        return;
      }
      if (obj.type == "tokenbalance") {
        console.log(obj)
        this.$store.commit("setTokenBalance", parseFloat(obj.balance).toFixed(3));
        return;
      }

      if (obj.type == "cashbackclaim") {
        this.$toast.success("Claimed " + obj.amount + " " + this.currency, {
          duration: 3000,
        });
        this.lucky777Game.fetchAccount();
        this.cashbackHelper.balanceOf();
        return;
      }

      if (obj.type == "balance") {
        if (obj.currency != this.currency) {
          return
        }

        let balance = parseFloat(obj.balance);
        if(obj.currency === 'TT'){
          this.$store.commit("setBalance", balance.toFixed(2));
        }else{
          this.$store.commit("setBalance", balance.toFixed(5));
        }
        this.$store.commit("setCurrency", obj.currency);
        return;
      }
      if (obj.type == "networkchange") {
        console.log(obj);
        var url = this.gameHelper.getRedirectUrl(this.state.game, obj.networkVersion)
        console.log(url)
        if (url != null) {
          window.location.href = url
        } else {
          var chains = this.gameHelper.getChainsList(this.state.game)
          window.Logger.log(chains)
          this.wrongNetwork.state = true
          this.wrongNetwork.body = "This game is not available on this Chain/Token<br/><br/>"
          this.wrongNetwork.body += "You can play this game on the next Cnain(Tokens)<br/><br/>"
          for (let index = 0; index < chains.length; index++) {
            const element = chains[index];
            this.wrongNetwork.body += `${element.blockchain_name}(${element.currency_codes.join(', ')})<br/><br/>`
          }
          this.wrongNetwork.body += "Please change to correct Chain/Token<br/>"
          this.wrongNetwork.body += `and click reload button below
                <div class="text-center"><a href="${window.location.href}" class="btn btn-secondary btn-sm mt-3">Reload</a></div>`


        }
        // 
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }


      //type=bethistory modis roca beti dafiksirda obj.address obj.bet obj.win
      if (obj.type == "bethistory") {
        return; //es calke unda mushavdebodes qveda logikas ro ar sheushalos
      }

      //type=error modis roca shecdoma dafiksirda
      if (obj.type == "error") {
        if (this.lucky777View) {
          this.lucky777View.enableButtons();
          this.lucky777View.rheelAction(["item_7", "item_a", "item_o"]);
        }

        this.state.lucky777MessageStr = obj.msg;
        this.state.lucky777message = true;
        setTimeout(
          function () {
            this.state.lucky777message = false;
          }.bind(this),
          3000
        );
        if (this.autobetSettings) {
          this.autobetSettings = this.gameHelper.autobetStop(
            this.autobetSettings
          );
          this.betAmount = this.autobetSettings.bet.current;
        }
      } else {
        this.state.lucky777win = false;
        this.state.gameBet = false;
        this.state.gameBetloader = false;
        this.state.buttonstate = "play";

        //type=connected modis roca metamaskze dakonektda
        if (obj.type == "connected") {
          this.$store.commit("setCurrencyEnabled", this.$store.getters.getConfig.games[this.state.game].tokens);
          this.lucky777View.enableButtons();
          this.state.lucky777message = false;
          this.state.gameBet = true;
        }

        //type=rejected modis roca userma metamaskze rejecti misca konektze da vachvenebt konektis gilaks
        //type=notconnected  modis roca useri araa dakonektebuli da vachvenebt konektis gilaks
        //da vmalavt mesijis divs
        if (obj.type == "rejected" || obj.type == "notconnected") {
          this.lucky777View.enableButtons();
          this.lucky777View.rheelAction(["item_7", "item_a", "item_o"]);
          this.state.lucky777message = false;
          this.state.buttonstate = "connect";
          this.autobetSettings = this.gameHelper.autobetStop();
        }

        //type=betloader modis roca beti gavida blockchainze da velodebit dadasturebas, vachvenenbt preloaders
        if (obj.type == "betloader") {
          this.state.gameBetloader = true;
        }
        if (obj.type == "transactionHash") {
          //this.lucky777View.rheelAction([]);
        }

        //type=betfinished modis roca userma an moigo an waago obj.win ari tanxa
        // tu tanxa=0 chveulebrivad betis knopkas vachvenebt
        // tu tanxa>0 chveulebrivad betis knopkas vachvenebt
        if (obj.type == "betfinished") {
          this.tokenHelper.balanceOf();
          console.log(obj)
          this.lucky777View.enableButtons();
          var timeout = this.lucky777View.rheelAction(obj.items);
          setTimeout(
            function () {
              if (obj.win > 0) {
                this.state.lucky777win = true;
                this.state.lucky777WinAmount = obj.win;
                //or wamshi vmalavt winis mesijs da vachvenebt betis knopkas
                this.$toast.success(
                  this.gameHelper.getMessage("you_won_amount", obj.win),
                  { duration: 3000 }
                );
                this.state.lucky777win = false;
                this.state.gameBet = true;
                this.$soundManager.play({ name: "win" });
              } else {
                this.state.gameBet = true;
              }
              if (this.lucky777Game.erc20Game) {
                this.lucky777Game.erc20Game.balanceOf()
              } else {
                this.lucky777Game.fetchAccount();
              }

              this.historyManager.addAllBetHistory(
                {
                  address: this.lucky777Game.playeraddress,
                  bet: obj.bet,
                  tx_id: obj.tx_id,
                  win: obj.win,
                  currency: obj.currency,
                });
              if (this.autobetSettings.state) {
                this.autobetProcess(obj);
              }
            }.bind(this),
            timeout
          );
        }
        if (obj.type == "betbutton") {
          this.state.gameBet = true;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.itemColumn {
  display: inline-block;
}

.container {
  background: #f0edf6 !important;
}

.hittxt {
  color: #9e9e9e;
}

.clearleft {
  clear: left;
}

.betstabbottomline {
  height: 2px;
  background: #cfcfcf;
  width: 90%;
  margin: 0 auto;
}

.betstab {
  width: 30%;
  float: left;
  margin-left: 2px;
  color: var(--tabtxt-color);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(0deg, var(--tabgs-color) 0%, var(--tabge-color) 100%);
  transition: 0.3s;
}

.betstabactive {
  color: var(--tabtxtselected-color);
  background: linear-gradient(0deg, var(--tabys-color) 0%, var(--tabye-color) 100%);
  transition: 0.3s;
}

.betstabHolder {
  justify-content: center;
  align-items: center;
}

.buttons {
  margin: 60px 0px;
}

.lucky777connect,
.gameBet {
  max-width: 300px;
  margin: 0 auto;
}

.lucky777connect .betbutton {
  margin: 0 auto !important;
  float: none;
  padding: 10px 50px;
}

.lucky777message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #cbcbcb;
}

.lucky777message {
  z-index: 1;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visible {
  opacity: 1;
  transition: 0.5s;
  pointer-events: auto;
}

.hidden {
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}

.visiblefast {
  opacity: 1;
  pointer-events: auto;
}

.hiddenfast {
  opacity: 0;
  pointer-events: none;
}

.betinput {
  background: linear-gradient(180deg, #f3f0f9 2.08%, #fefefe 100%);
  box-shadow: inset 0px 3px 0px #dedbe7;
  border-radius: 21px;
  font-size: 25px;
  border: none;
  text-align: center;
  padding: 5px;
  padding-top: 0px;
  max-width: 300px;
  width: 59%;
  height: 50px;
}

.betbutton {
  background: #262626;
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 5px 5px 5px 10px;
  margin-top: 10px;
  float: left;
}

.randombutton {
  background: #262626;
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 10px;
  float: left;
}

.betModifiers {
  width: 50px;
  background: #262626;
  border-radius: 25px;
  border: 5px solid #f0edf7;
  color: #ffffff;
  text-align: center;
  padding: 7px 0px;
  top: -2px;
  position: relative;
  margin-left: 5px;
  height: 50px;
  box-shadow: -1.86814px -1.86814px 2.80221px #ffffff,
    1.40111px 1.40111px 2.80221px rgb(174 174 192 / 40%);
}

.bet {
  width: 5em;
}

.spinner-border {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-center;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  top: -3px;
  position: relative;
}

@media (max-width: 992px) {
  .buttons {
    margin: 0 auto;
    max-width: 90%;
    margin-top: 1.5em;
  }
}
</style>