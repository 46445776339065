import * as PIXI from 'pixi.js'
import TWEEN from "@tweenjs/tween.js";


export default class Dot extends PIXI.Sprite {
    bg = null
    fg = null
    state = 0
    init() {
        this.bg = PIXI.Sprite.from(PIXI.Texture.from("dotdisabled"));
        this.addChild(this.bg);

        this.fg = PIXI.Sprite.from(PIXI.Texture.from("dotactive"));
        this.addChild(this.fg);

    }


    fadeOut(delay,callback) {
        new TWEEN.Tween(this.fg).
            to({ alpha: 0 }, 100).
            delay(delay).
            easing(TWEEN.Easing.Linear.None).
            onComplete(function () {
                if(callback){
                    callback()
                }
            }.bind(this)).
            start()
    }

    fadeIn(delay,callback) {
        new TWEEN.Tween(this.fg).
            to({ alpha: 1 }, 100).
            delay(delay).
            easing(TWEEN.Easing.Linear.None).
            onComplete(function () {
                this.fadeOut(0,callback)
            }.bind(this)).
            start()
    }

}


