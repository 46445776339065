
import Game from "../../services/game"
import Web3 from "web3";

export default class Lucky777Game extends Game {

  betHistory = []
  betHistoryAll = {
    my: [],
    all: []
  }
  currentBetHistoryList = 'ALL'
  items = [
    '',
    'item_7',
    'item_b',
    'item_w',
    'item_z',
    'item_q',
    'item_o',
    'item_a',
    'item_l'
  ]


  balance = 0

  win = 0
  result = []
  amount = 0.001
  tx_id = ''

  constructor(_messageHandler, _Contract, _game) {
    super(_messageHandler, _Contract, _game)
    this.logsHandler = this.getLogsHandler
  }


  betHandler(amount) {
    if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {
      this.betMainHandler(amount)
    } else {
      this.betTokenHandler(amount)
    }
  }

  async betTokenHandler(amount) {
    this.result = []

    if (!this.connected) {
      this.messageHandler({ type: 'error', msg: 'Not connected' })
      return
    }


    this.amount = amount;
    this.messageHandler({ type: 'betloader' })
    if (localStorage) {
      localStorage.setItem('betAmount', this.amount);
    }

    var betAmt = Web3.utils.toWei("" + this.amount, this.tokenObj.decimals)
    var sendData = {
      from: this.account
    }

    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet(this.tokenObj.address, betAmt).estimateGas(
        { from: this.account }
      )
      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)
      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)
      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }
    console.log(sendData)



    this.contract.methods
      .bet(this.tokenObj.address, betAmt)
      .send(sendData)
      .on('transactionHash', function (hash) {
        this.messageHandler({ type: 'transactionHash', hash: hash })
      }.bind(this))
      .then(function (result) {
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, this.tokenObj.decimals);
        this.tx_id = result.transactionHash
        this.messageHandler({
          type: 'betfinished',
          win: this.win,
          bet: this.amount,
          currency: this.tokenObj.name,
          items: [
            this.items[parseInt(result.events.Bet.returnValues.output[0])],
            this.items[parseInt(result.events.Bet.returnValues.output[1])],
            this.items[parseInt(result.events.Bet.returnValues.output[2])],
            this.win
          ]
        })
      }.bind(this), function (params) {
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }

  async betMainHandler(amount) {
    this.result = []

    if (!this.connected) {
      this.messageHandler({ type: 'error', msg: 'Not connected' })
      return
    }


    this.amount = amount;
    this.messageHandler({ type: 'betloader' })
    if (localStorage) {
      localStorage.setItem('betAmount', this.amount);
    }


    var sendData = {
      from: this.account,
      value: Web3.utils.toWei("" + this.amount, 'ether')
    }

    if (this.tokenObj.extraFeeGasLimit > 0) {
      var gasLimit = await this.contract.methods.bet().estimateGas(
        {
          from: this.account,
          value: Web3.utils.toWei("" + this.amount, 'ether')
        }
      )
      gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

      var gasPrice = await window.web3.eth.getGasPrice()
      gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


      sendData.gasLimit = gasLimit
      sendData.gasPrice = gasPrice
    }


    console.log(sendData)

    this.contract.methods
      .bet()
      .send(sendData)
      .on('transactionHash', function (hash) {
        this.messageHandler({ type: 'transactionHash', hash: hash })
      }.bind(this))
      .then(function (result) {
        this.win = Web3.utils.fromWei(result.events.Bet.returnValues.win, 'ether');
        this.tx_id = result.transactionHash
        this.messageHandler({
          type: 'betfinished',
          win: this.win,
          bet: this.amount,
          currency: this.tokenObj.name,
          items: [
            this.items[parseInt(result.events.Bet.returnValues.output[0])],
            this.items[parseInt(result.events.Bet.returnValues.output[1])],
            this.items[parseInt(result.events.Bet.returnValues.output[2])],
            this.win
          ]
        })
      }.bind(this), function (params) {
        this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
        this.messageHandler({ type: 'betbutton' })
      }.bind(this))

  }

  /*
  getLogsHandler(event) {
    if (event.event == "Bet") {
      var betObj = {
        address: event.returnValues.player,
        tx_id: event.transactionHash,
        bet: Web3.utils.fromWei(event.returnValues.bet, 'ether'),
        win: Web3.utils.fromWei(event.returnValues.win, 'ether')
      }
      this.addBetHistory(betObj, 'ALL')
    }

  }*/

}

