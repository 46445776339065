import * as PIXI from 'pixi.js'
import Dot from "./Dot"
import TWEEN from "@tweenjs/tween.js";

export default class Dots extends PIXI.Sprite {
    dots = []
    dotPos = [
        [25, 135],
        [25, 65],
        [25, -5],
        
        
        //[0, 200],
        //[-15, 270],
        [125, -5],
        [225, -5],
        [325, -5],
        [425, -5],
        [525, -5],
        [625, -5],
        [625, 65],
        [625, 135],
        [525, 135],
        [425, 135],
        [325, 135],
        [225, 135],
        [125, 135],
        
        
        
        
        
        
        //[660, 270],
        //[645, 200],

    ]

    init() {
        for (let index = 0; index < this.dotPos.length; index++) {
            var dt = new Dot()
            dt.init()
            dt.x = this.dotPos[index][0]
            dt.y = this.dotPos[index][1]
            //dt.state = index % 2
            if (index % 2 == 0) {
                //dt.fg.alpha = 0
            }
            dt.fg.alpha = 0
            this.addChild(dt)
            this.dots.push(dt)
        }

        this.animateRotation()

    }

    animateRotation() {
        for (let index = 0; index < this.dots.length; index++) {
            if (index == this.dots.length - 1) {
                this.dots[index].fadeIn(150 * index, function () {
                    this.animateRotation()
                }.bind(this))
            } else {
                this.dots[index].fadeIn(150 * index)
            }
        }

    }

    animate() {
        for (let index = 0; index < this.dots.length; index++) {
            this.dots[index].state = 1 - this.dots[index].state
        }
        var twObj = { percent: 0 }
        new TWEEN.Tween(twObj).
            to({ percent: 1 }, 500).
            delay(200).
            easing(TWEEN.Easing.Linear.None).
            onUpdate(function () {
                for (let i = 0; i < this.dots.length; i++) {
                    if (this.dots[i].state == 1) {
                        this.dots[i].fg.alpha = twObj.percent
                    } else {
                        this.dots[i].fg.alpha = 1 - twObj.percent
                    }
                }

            }.bind(this)).
            onComplete(function () {
                this.animate()
            }.bind(this)).
            start()
    }


}


