<template>
  <div id="mainlucky777"></div>
</template>
<script>
import * as PIXI from "pixi.js"; 
import Dots from "./Dots";
import MainButton from "./MainButton";
import Rheels from "./Rheels";
import TWEEN from "@tweenjs/tween.js";
import { store } from '@/store';

export default {
  name: "CanvasRenderer",
  components: {},
  data() {
    const state = {
      PixiApp: null,
      main: null,
      game: null,
      preloader: null,
      rheels: null,
      winHolder: null,
      winText: null,
      betBtn: null,
      betMaxBtn: null,
      claimBtnActive: false,
      claimAmount:""
    };

    return {
      state,
    };
  },
  props: ["canvasHandler"],
  mounted() {
    this.canvasHandler(this);
    this.state.PixiApp = new PIXI.Application({
      width: 100,
      height: 100,
      backgroundColor: 0xf0edf6,
      antialias: true,
      autoDensity: true,
      resolution: window.devicePixelRatio,
      resizeTo: document.getElementById("mainlucky777"),
    });
    // PIXI.utils.clearTextureCache();
    var loader = PIXI.Loader.shared;
    loader.reset();
    var assets = [
      ["betmax", "/games/lucky777/betmax.png"],
      ["betmaxpressed", "/games/lucky777/betmaxpressed.png"],
      ["bet", "/games/lucky777/bet.png"],
      ["betpressed", "/games/lucky777/betpressed.png"],
      ["bottom", "/games/lucky777/bottom.png"],
      ["dotactive", "/games/lucky777/dotactive.png"],
      ["dotactive_yelow", "/games/lucky777/dotactive_yelow.png"],
      ["dotdisabled", "/games/lucky777/dotdisabled.png"],
      ["item_7", "/games/lucky777/item_7.png"],
      ["item_a", "/games/lucky777/item_a.png"],
      ["item_b", "/games/lucky777/item_b.png"],
      ["item_l", "/games/lucky777/item_l.png"],
      ["item_o", "/games/lucky777/item_o.png"],
      ["item_q", "/games/lucky777/item_q.png"],
      ["item_w", "/games/lucky777/item_w.png"],
      ["item_z", "/games/lucky777/item_z.png"],
      ["main", "/games/lucky777/main.png"],
      ["payout", "/games/lucky777/payout.png"],
      ["payoutpressed", "/games/lucky777/payoutpressed.png"],
      ["win_left", "/games/lucky777/win_left.png"],
      ["win_right", "/games/lucky777/win_right.png"],
      ["info", "/games/lucky777/info.png"],
      ["infopressed", "/games/lucky777/infopressed.png"],
      ["claim", "/games/lucky777/claim.png"],
      ["claimpressed", "/games/lucky777/claimpressed.png"],
    ];

    for (let index = 0; index < assets.length; index++) {
      try {
        loader.add(assets[index][0], assets[index][1]);
      } catch (error) {
        window.Logger.log(error);
      }
    }
    loader.onProgress.add(this.onProgress.bind(this));
    loader.onError.add(this.onError.bind(this));
    loader.onComplete.add(this.onComplete.bind(this));
    loader.load();

    this.state.preloader = new PIXI.Text("0%", {
      fontFamily: "Arial",
      fontSize: 34,
      fill: 0x464646,
      align: "center",
      fontWeight: "bold",
      dropShadow: true,
      dropShadowAlpha: 0.2,
      dropShadowBlur: 4,
      dropShadowDistance: 2,
    });
    this.state.preloader.anchor.set(0.5, 0.5);
    this.state.PixiApp.stage.addChild(this.state.preloader);

    document
      .getElementById("mainlucky777")
      .appendChild(this.state.PixiApp.view);

    setTimeout(
      function () {
        this.matchSizes();
      }.bind(this),
      0
    );
  },
  watch: {
    windowSize: function () {
      this.matchSizes();
    },
  },
  methods: {
    initGame() {
      this.state.game = new PIXI.Sprite();
      this.state.PixiApp.stage.addChild(this.state.game);
      this.state.game.alpha = 0;
      var main = PIXI.Sprite.from(PIXI.Texture.from("main"));
      this.state.game.addChild(main);

      this.state.game.winHolder = new PIXI.Sprite();
      this.state.game.winHolder.x = 110;
      this.state.game.winHolder.y = 65;
      this.state.game.addChild(this.state.game.winHolder);

      var win_left = PIXI.Sprite.from(PIXI.Texture.from("win_left"));
      this.state.game.winHolder.addChild(win_left);

      var win_right = PIXI.Sprite.from(PIXI.Texture.from("win_right"));
      win_right.x = 470;
      this.state.game.winHolder.addChild(win_right);

      this.state.game.winText = new PIXI.Text(this.state.claimAmount, {
        dropShadowAngle: 0.7,
        dropShadowBlur: 4,
        dropShadowDistance: 7,
        fontSize: 34,
        lineJoin: "round",
      });
      this.state.game.winText.x = 250;
      this.state.game.winText.y = 35;
      this.state.game.winText.anchor.set(0.5, 0.5);
      this.state.game.winHolder.addChild(this.state.game.winText);

      var bottom = PIXI.Sprite.from(PIXI.Texture.from("bottom"));
      bottom.x = 0;
      bottom.y = 530;
      this.state.game.addChild(bottom);

      var dots = new Dots();
      dots.init();
      this.state.game.addChild(dots);
      //  this.state.betBtn
      this.state.betMaxBtn = new MainButton();
      this.state.betMaxBtn.init(
        "betmax",
        "betmaxpressed",
        this.onClick.bind(this)
      );
      this.state.betMaxBtn.x = 50;
      this.state.betMaxBtn.y = 665;
      this.state.betMaxBtn.name = "betmax";
      this.state.game.addChild(this.state.betMaxBtn);

      this.state.betBtn = new MainButton();
      this.state.betBtn.init("bet", "betpressed", this.onClick.bind(this));
      this.state.betBtn.x = 235;
      this.state.betBtn.y = 630;
      this.state.betBtn.name = "bet";
      this.state.game.addChild(this.state.betBtn);

      var payout = new MainButton();
      payout.init("payout", "payoutpressed", this.onClick.bind(this));
      payout.x = 490;
      payout.y = 679;
      payout.name = "payout";
      this.state.game.addChild(payout);

      var info = new MainButton();
      info.init("info", "infopressed", this.onClick.bind(this));
      info.x = 80;
      info.y = 55;
      info.scale.x = info.scale.y = 0.9;
      info.name = "info";
      this.state.game.addChild(info);

      var claim = new MainButton();
      claim.init("claim", "claimpressed", this.onClick.bind(this));
      claim.x = 555;
      claim.y = 55;
      claim.scale.x = claim.scale.y = 0.9;
      claim.name = "claim";
      this.state.game.addChild(claim);

      this.state.game.rheels = new Rheels();
      this.state.game.rheels.init();
      this.state.game.rheels.x = 105;
      this.state.game.rheels.y = 245;
      this.state.game.addChild(this.state.game.rheels);

      new TWEEN.Tween(this.state.game)
        .to({ alpha: 1 }, 480)
        .delay(100)
        .easing(TWEEN.Easing.Linear.None)
        .start();

      setTimeout(
        function () {
          this.matchSizes();
        }.bind(this),
        10
      );
    },
    onProgress(e) {
      if (this.state.preloader) {
        this.state.preloader.text = "" + parseInt(e.progress);
      }
    },

    onError(e) {
      window.Logger.log("onError " + e);
    },

    onComplete(e) {
      window.Logger.log("onComplete " + e);
      this.state.PixiApp.stage.removeChild(this.state.preloader);
      this.state.preloader = null;
      this.initGame();
    },
    enableButtons() {
      if (this.state.betMaxBtn) {
        this.state.betMaxBtn.enable();
        this.state.betBtn.enable();
      }
    },
    setCashback(cb) {
      this.state.claimBtnActive = parseFloat(cb) > 0;
      this.state.claimAmount = `${cb} ${store.getters.getConfig.currency_code}`;
      if (this.state.game) {
        this.state.game.winText.text = this.state.claimAmount
      }
    },
    claimButton(act) {
      this.state.claimBtnActive = act;
    },
    rheelAction(arr) {
      if (this.state.game == null) {
        return;
      }
      var timeout = 10;
      if (arr.length == 0) {
        this.state.game.rheels.start();
      } else {
        timeout = this.state.game.rheels.stop(arr[0], arr[1], arr[2]);
      }
      return timeout;
    },

    onClick(caller) {
      if (caller.name == "claim") {
        if (this.state.claimBtnActive) {
          this.canvasHandler("claim");
        }
      }
      if (caller.name == "info") {
        this.canvasHandler("info");
      }
      if (caller.name == "bet") {
        this.state.betMaxBtn.disable();
        this.state.betBtn.disable();

        this.canvasHandler("bet");
      }
      if (caller.name == "betmax") {
        this.state.betMaxBtn.disable();
        this.state.betBtn.disable();
        this.canvasHandler("betmax");
      }
      if (caller.name == "payout") {
        this.canvasHandler("payout");
      }
    },
    matchSizes() {
      var width = document.getElementById("mainlucky777").clientWidth;
      var height = document.getElementById("mainlucky777").clientHeight;
      var scale = Math.min(width / 715, height / 957);
      if (this.state.game) {
        this.state.game.scale.x = scale;
        this.state.game.scale.y = scale;

        this.state.game.x = (width - 715 * scale) / 2;
      }

      if (this.state.preloader) {
        this.state.preloader.position.set(width / 2, height / 2);
      }
    },
  },
};
</script>

<style scoped>
#mainlucky777 {
  background: #f0edf6;
  height: 60vh;
}
</style>