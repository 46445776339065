import * as PIXI from 'pixi.js'
import Rheel from "./Rheel";
//import SoundManager from "@/utils/SoundManager";
import { store } from '@/store';

export default class Rheels extends PIXI.Sprite {
    rheel_1 = null
    rheel_2 = null
    rheel_3 = null
    sound = null
    init() {
        this.rheel_1 = new Rheel()
        this.rheel_1.x = 0
        this.rheel_1.y = 0
        this.rheel_1.init()
        this.addChild(this.rheel_1)


        this.rheel_2 = new Rheel()
        this.rheel_2.x = 177
        this.rheel_2.y = 0
        this.rheel_2.init()
        this.addChild(this.rheel_2)

        this.rheel_3 = new Rheel()
        this.rheel_3.x = 354
        this.rheel_3.y = 0
        this.rheel_3.init()
        this.addChild(this.rheel_3)
        //156x198

    }


    start() {
        //this.sound = new SoundManager().play({ name: 'loop', volume: 1, loop: false })
        this.rheel_1.start(0)
        this.rheel_2.start(400)
        this.rheel_3.start(600)
    }

    stop(item_1, item_2, item_3) {
        var timeouts = [0, 400, 800]
        if (store.getters.getFastOn) {
            timeouts = [0, 200, 400]
        }

        setTimeout(function () {
            this.rheel_1.stop(item_1)
        }.bind(this), timeouts[0])

        setTimeout(function () {
            this.rheel_2.stop(item_2)
        }.bind(this), timeouts[1])

        setTimeout(function () {
            this.rheel_3.stop(item_3, function () {
                if (this.sound) {
                    this.sound.stop()
                }
            }.bind(this))

        }.bind(this), timeouts[2])

        return timeouts[2] + 1000
    }
}


