
import Web3 from "web3";


export default class Promo2Helper {
    contract = null
    address = null
    account = null
    Promo2 = null
    lastBlock = 0
    getLogsDataInterval = 0
    processedTxs = []

    constructor(_messageHandler, _Promo2) {
        this.messageHandler = _messageHandler
        this.Promo2 = _Promo2
    }

    getNetworkVersion() {
        var networkV = 0
        try {
            if (window.web3.currentProvider.isViaWallet) {
                networkV = window.web3.currentProvider.chainId
            } else {
                networkV = window.web3.eth.currentProvider.networkVersion
            }
        } catch (error) {
            window.Logger.log(error)
        }

        return networkV
    }

    claim() {
        this.contract.methods
            .claim()
            .send({ from: this.account })
            .then(function (result) {
                window.Logger.log(result)
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'Promo2 rejected code:' + params })
            }.bind(this))
    }



    onConnet(_account) {
        window.Logger.log("onConnet " + this.getNetworkVersion() + " " + _account)
        if (this.Promo2.networks[this.getNetworkVersion()] == null) {
            return;
        }
        window.Logger.log("onConnet promo2 " + this.account)
        this.account = _account
        window.Logger.log("this.account promo2 " + this.account)
        this.address = this.Promo2.networks[this.getNetworkVersion()].address;
        this.contract = new window.web3.eth.Contract(
            this.Promo2.abi,
            this.address
        );
        if (window.promo2address) {
            if (window.promo2address[this.address] == true) {
                return
            }
        }
        if (window.promo2address == null) {
            window.promo2address = {}
        }
        window.promo2address[this.address] = true

        window.web3.eth.getBlockNumber().then(function (data) {
            window.Logger.log("aaaaaaaaaaaa promo2 started")
            this.lastBlock = data;
            this.getLogsDataInterval = setInterval(this.getLogsData.bind(this), 1000);
        }.bind(this));
    }

    destroy() {
        clearInterval(this.getLogsDataInterval)
        if (window.promo2address) {
            window.promo2address[this.address] = false
        }
    }

    getLogsData() {
        if (this.lastBlock > 0) {
            this.contract.events.allEvents({
                fromBlock: this.lastBlock
            }).on('data', function (event) {
                if (event.event == "DailyCashback") {
                    window.Logger.log(event)

                    window.Logger.log("this.processedTxs.indexOf " + this.processedTxs.indexOf(event.transactionHash))
                    if (this.processedTxs.indexOf(event.transactionHash) == -1) {
                        this.processedTxs.push(event.transactionHash)
                        this.lastBlock = event.blockNumber + 1
                        switch (event.event) {
                            case 'DailyCashback':
                                window.Logger.log(`DailyCashback ${this.account.toUpperCase()} = ${event.returnValues.player.toUpperCase()}`)
                                if (
                                    this.account.toUpperCase() == event.returnValues.player.toUpperCase()
                                ) {
                                    window.Logger.log("call dailycashback")
                                    this.messageHandler({
                                        type: 'dailycashback',
                                        address: event.returnValues.player,
                                        amount: Web3.utils.fromWei(event.returnValues.amount, 'ether')
                                    })
                                }

                                break;
                            default:
                                break;
                        }

                    }
                }


                if (this.processedTxs.length > 30) {
                    this.processedTxs.pop()
                }

            }.bind(this))
        }
    }



}

